import React, { useState, useEffect } from "react";
import styles from "./Diet.module.css";
import NutritionProgress from "../../../models/NutritionProgress";
import SearchDropdown from "./SearchDropdown/SearchDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Datepicker.css";
import { useProfile } from "../../../ProfileContext";
import { useDispatch, useSelector } from "react-redux";
import useFetchUser from "../../../hooks/useFetchUser";
import { useTranslation } from "react-i18next";
import ExportWindow from "./ExportWindow/ExportWindow";
import { validateDigitsInput } from "../../../utils/inputUtils";
import DietPlanTable from "./DietPlanTable/DietPlanTable";
import {
  fetchRation,
  deleteDietItem,
  addProduct,
  updateDietItem,
} from "../../../redux/slices/rationSlice";
import {
  initializeDietItems,
  calculateNutritionProgress,
} from "../../../utils/dietItemUtils";
import { createSelector } from "reselect";

const initialNutritionProgressArray = [
  new NutritionProgress("Calories", 0, 100, "calories"),
  new NutritionProgress("Carbs", 0, 100, "carbs"),
  new NutritionProgress("Protein", 0, 100, "protein"),
  new NutritionProgress("Fat", 0, 100, "fat"),
];
const selectDietItems = (state) => state.ration.dietItems;

const initializeDietItemsSelector = createSelector(
  [selectDietItems],
  (dietItems) => initializeDietItems(dietItems)
);

const Diet = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const dietItems = useSelector(initializeDietItemsSelector);
  const [displayTimes, setDisplayTimes] = useState([]);
  const { t, i18n } = useTranslation();
  const { selectedProfile } = useProfile();
  const user = useSelector((state) => state.profile.user); // Access user data from Redux
  const [nutritionProgressArray, setNutritionProgressArray] = useState(
    initialNutritionProgressArray
  );
  const [startDate, setStartDate] = useState(new Date());
  const [editingItemId, setEditingItemId] = useState(null);
  const [grams, setGrams] = useState(100); // Default grams

  const [formData, setFormData] = useState({
    time: null,
    name: "",
    grams: 0,
    calories: 0,
    protein: 0,
    fat: 0,
    carbs: 0,
  });

  const handleGramsChange = (e) => {
    const inputValue = e.target.value;
    const validatedValue = validateDigitsInput(inputValue);
    setGrams(validatedValue);
  };

  const handleEdit = (index) => {
    const item = dietItems[index];
    if (!item) return;

    setEditingItemId(index); // Set the index of the item being edited
    setFormData({
      time:
        item.time instanceof Date
          ? item.time.toTimeString().slice(0, 5)
          : "00:00",
      name: item.product.name,
      grams: item.grams,
      calories: item.product.calories,
      protein: item.product.protein,
      fat: item.product.fat,
      carbs: item.product.carbs,
    });
  };

  const handleDelete = (id) => {
    dispatch(deleteDietItem(id));
  };

  const handleSave = (index) => {
    const updatedItem = dietItems[index]; // Get the item to update
    const [hours, minutes] = formData.time.split(":");
    const updatedTime = new Date(startDate);
    updatedTime.setHours(hours);
    updatedTime.setMinutes(minutes);
    const updatedTimeString = updatedTime.toTimeString().slice(0, 5);

    updatedItem.time = updatedTime;
    updatedItem.grams = formData.grams;

    dispatch(
      updateDietItem({
        mealId: updatedItem.id,
        updatedData: {
          time: updatedTimeString,
          grams: updatedItem.grams,
          productId: updatedItem.product.id,
        },
        profileId: selectedProfile.id,
        startDate: new Date(startDate),
      })
    );

    setEditingItemId(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeTime = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, time: value }));
  };

  const handleProductAdd = async (product, time) => {
    const [hours, minutes] = time.split(":");
    const newTime = new Date(startDate);
    newTime.setHours(parseInt(hours, 10));
    newTime.setMinutes(parseInt(minutes, 10));

    const data = {
      time,
      grams,
      productId: product.id,
      date: startDate.toISOString().split("T")[0], // Include the date in the data
    };

    if (!selectedProfile) {
      return;
    }

    try {
      await dispatch(
        addProduct({
          profileId: selectedProfile.id,
          data,
        })
      );
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const onUserFetchComplete = () => {
    setLoading(false);
  };

  useFetchUser(onUserFetchComplete);

  useEffect(() => {
    const summedValues = {
      Calories: 0,
      Carbs: 0,
      Protein: 0,
      Fat: 0,
    };

    dietItems.forEach((item) => {
      summedValues.Calories += Number(item.product.calories);
      summedValues.Carbs += Number(item.product.carbs);
      summedValues.Protein += Number(item.product.protein);
      summedValues.Fat += Number(item.product.fat);
    });

    const nutritionProgressItems = calculateNutritionProgress(
      summedValues,
      user,
      t
    );

    // Only update state if there is a change
    if (
      JSON.stringify(nutritionProgressArray) !==
      JSON.stringify(nutritionProgressItems)
    ) {
      setNutritionProgressArray(nutritionProgressItems);
    }
  }, [dietItems, user, t, nutritionProgressArray]);

  useEffect(() => {
    if (selectedProfile) {
      dispatch(fetchRation({ profileId: selectedProfile.id, startDate }));
    }
  }, [startDate, selectedProfile]);

  useEffect(() => {
    // Calculate shouldDisplayTime for each diet item when dietItems or their times change
    const newDisplayTimes = dietItems.map((item, index) => {
      return (
        index === 0 ||
        (item.time instanceof Date &&
          item.time.getTime() !== (dietItems[index - 1]?.time?.getTime() || 0))
      );
    });

    setDisplayTimes(newDisplayTimes);
  }, [dietItems]);

  if (loading) return <div></div>;
  return (
    <div className={`${styles.container} container`}>
      <div
        className={`row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-xl-2 row-cols-xxl-2 w-100 gx-0`}
      >
        <div
          className={`col-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 ${styles["left-side"]} `}
        >
          <div className={styles["progress-bars-table"]}>
            <div className={`row`}>
              {nutritionProgressArray.map((item) => (
                <div className={`col`} key={item.name}>
                  <div className={`${styles["progress-bar-box"]}`}>
                    <div className={`${styles["progress-title"]}`}>
                      {item.name}
                    </div>
                    <div className={`progress ${styles.progress}`}>
                      <div
                        className={`progress-bar ${
                          styles[`progress-bar-${item.idName.toLowerCase()}`]
                        }`}
                        role="progressbar"
                        style={{
                          width: `${(item.current / item.total) * 100}%`,
                        }}
                        aria-valuenow={item.current}
                        aria-valuemin="0"
                        aria-valuemax={item.total}
                      ></div>
                    </div>
                    <div
                      className={`${styles["progress-number"]}`}
                    >{`${item.current}/${item.total}`}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles["meal-search-table"]}>
            <div className={`row m-0`}>
              <SearchDropdown
                onProductAdd={handleProductAdd}
                grams={grams}
                onGramsChange={handleGramsChange}
              />
            </div>
          </div>
        </div>
        <div
          className={`col-7 col-xs-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 ${styles["right-side"]} `}
        >
          <div className={styles["diet-plan"]}>
            <div className={styles["diet-plan-header"]}>
              <div className={styles["diet-plan-header-text"]}>
                {t(`diet.dietPlan.header`)}
              </div>
              <DatePicker
                className={styles.datepicker}
                showIcon
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
            <DietPlanTable
              dietItems={dietItems}
              displayTimes={displayTimes}
              editingItemId={editingItemId}
              formData={formData}
              handleChange={handleChange}
              handleChangeTime={handleChangeTime}
              handleSave={handleSave}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
            <div>
              <ExportWindow
                selectedProfile={selectedProfile}
                t={t}
              ></ExportWindow>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diet;
