import { useDispatch } from "react-redux";
import axios from "axios";
import { setUser } from "../redux/slices/profileSlice"; // Adjust the import path if needed
import { useEffect, useState } from "react"; // Import useState
import {
  calculateDailyCalorieNorm,
  calculateDailyProteinLimit,
  calculateDailyFatLimit,
  calculateDailyCarbsLimit,
} from "../utils/healthCalculations"; // Import from the new file
import { useProfile } from "../ProfileContext";

const useFetchUser = (
  onInitialUserSet = () => {},
  onUserStateSet = () => {},
  callBack = () => {} // Add the callback parameter
) => {
  const { selectedProfile } = useProfile();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!selectedProfile) {
          return;
        }

        setLoading(true); // Set loading to true before fetching data

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/profile/${selectedProfile.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        const data = response.data;

        // Extract necessary data
        const weight = data.weight || 0;
        const height = data.height || 0;
        const age = data.age || 0;
        const activityCoef = data.activity?.multiplier || 1;
        let selectedCalorieLimit = data.selectedCalorieLimit || 0;
        const gender = data.gender || "unknown";
        const proteinLimit = data.dailyProteinLimit;
        const carbsLimit = data.dailyCarbsLimit;
        const fatLimit = data.dailyFatLimit;

        // Calculate values only if necessary data is present
        const dailyCalorieNorm =
          weight && height && age
            ? calculateDailyCalorieNorm({
                weight,
                height,
                age,
                gender,
                activityCoef,
              })
            : data.dailyCalorieNorm || "";
        if (selectedCalorieLimit === null || selectedCalorieLimit <= 0) {
          selectedCalorieLimit = dailyCalorieNorm;
        }
        const finalSelectedCalorieLimit = selectedCalorieLimit;
        const dailyProteinLimit = !proteinLimit
          ? calculateDailyProteinLimit(finalSelectedCalorieLimit)
          : proteinLimit || "";
        const dailyFatLimit = !fatLimit
          ? calculateDailyFatLimit(finalSelectedCalorieLimit)
          : fatLimit || "";
        const dailyCarbsLimit = !carbsLimit
          ? calculateDailyCarbsLimit(finalSelectedCalorieLimit)
          : carbsLimit || "";
        const profileData = {
          name: data.name || "",
          age: data.age || "",
          gender: data.gender || "",
          weight: data.weight || "",
          height: data.height || "",
          waist: data.waist || "",
          hips: data.hips || "",
          goalWeight: data.goalWeight || "",
          activity: {
            id: data.activity.id,
            description: data.activity.description || "",
            multiplier: data.activity.multiplier,
          },
          bodyMassIndex:
            data.bodyMassIndex ||
            (weight && height ? (weight / (height / 100) ** 2).toFixed(2) : ""),
          dailyCalorieNorm,
          selectedCalorieLimit: finalSelectedCalorieLimit,
          dailyProteinLimit,
          dailyFatLimit,
          dailyCarbsLimit,
        };

        dispatch(setUser(profileData));

        onInitialUserSet(profileData); // Call callback if provided
        onUserStateSet(profileData); // Call callback if provided
        callBack(); // Call the callback once fetch is complete
      } catch (error) {
        console.error("Error fetching profile data:", error);
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    fetchData();
  }, [selectedProfile]);

  return { loading }; // Return loading state
};
export default useFetchUser;
