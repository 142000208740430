import React, { useState, useEffect, useRef } from "react";
import styles from "./SearchDropdown.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "../../../../ProfileContext";
import {
  fetchSearchProducts,
  resetSearch,
} from "../../../../redux/slices/productSearchSlice";

const SearchDropdown = ({ onProductAdd, grams, onGramsChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchResults, hasMore, loading } = useSelector(
    (state) => state.productSearch
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [time, setTime] = useState("10:00");
  const [searchPageSize] = useState(50);
  const { selectedProfile } = useProfile();

  const handleSearchChange = (e) => {
    const inputValue = e.target.value;
    setSearchTerm(inputValue);

    // Reset the search if the input is cleared
    if (inputValue.trim() === "") {
      dispatch(resetSearch());
    } else {
      // Reset previous search results and fetch fresh data for the new search term
      dispatch(resetSearch()); // Clear previous results
      dispatch(
        fetchSearchProducts({
          searchTerm: inputValue,
          page: 0,
          size: searchPageSize,
        })
      ); // Fetch the first page
    }
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const handleProductSelect = (product) => {
    setSearchTerm("");
    dispatch(resetSearch());
  };

  const handleProductAddClick = (product) => {
    onProductAdd(product, time);
  };

  const fetchMoreResults = () => {
    if (hasMore && searchTerm.trim()) {
      // Fetch more results for the current search term
      dispatch(
        fetchSearchProducts({
          searchTerm,
          page: Math.ceil(searchResults.length / searchPageSize),
          size: searchPageSize,
        })
      );
    }
  };

  useEffect(() => {
    // Reset search state when the component mounts or the search term changes
    return () => {
      setSearchTerm("");
      dispatch(resetSearch());
    };
  }, [dispatch, selectedProfile]);

  return (
    <div className={`${styles.searchDropdown}`}>
      <form onSubmit={handleSearchSubmit}>
        <div className={`${styles.searchInputContainer}`}>
          <FontAwesomeIcon icon={faMagnifyingGlass} className={styles.icon} />
          <div className={`${styles["search-input-wrapper"]}`}>
            <input
              type="text"
              placeholder={t("productSearchDropdown.searchPlaceholder")}
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchInput}
            />
          </div>
          <div className={`${styles["search-pickers"]}`}>
            <div className={`${styles["grams-picker"]}`}>
              <input
                type="text"
                name="grams"
                value={`${grams}`}
                onChange={onGramsChange}
                className={`form-control shadow-none ${styles["grams-input"]}`}
              />
              <span>{t("productSearchDropdown.grams")}</span>
            </div>
            <div className={`${styles["time-picker"]}`}>
              <input
                type="time"
                name="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                className="form-control shadow-none"
              />
              <span>{t("productSearchDropdown.time")}</span>
            </div>
          </div>
        </div>
      </form>
      {searchResults.length > 0 && (
        <InfiniteScroll
          dataLength={searchResults.length}
          next={fetchMoreResults}
          hasMore={hasMore}
          loader={loading && <p>Loading...</p>}
          endMessage={<div></div>}
          height={300}
          scrollThreshold={0.99}
          className={`${styles["diet-items-scrollable-container"]}`}
        >
          <ul className={styles.dropdownMenu}>
            {searchResults.map((product) => (
              <li
                key={product.id}
                className={styles.dropdownItem}
                onClick={() => {
                  handleProductSelect(product);
                  handleProductAddClick(product);
                }}
              >
                <div className={styles.dropdownItemContent}>
                  <div className={styles.dropdownItemInfo}>
                    <div
                      className={`${styles.dropdownItemLabel} text-truncate`}
                    >
                      {product.name}
                    </div>
                    <div className={`${styles.dropdownItemText}`}>
                      {t("productSearchDropdown.kcal")}: {product.calories}{" "}
                      {t("productSearchDropdown.protein")}: {product.protein}{" "}
                      {t("productSearchDropdown.carbs")}: {product.carbs}{" "}
                      {t("productSearchDropdown.fat")}: {product.fat}
                    </div>
                  </div>
                  <div className={styles.dropdownButtons}>
                    <button className={`${styles.customButton}`}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        className={styles.icon}
                        size="lg"
                      />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.hackyOffPageElement}></div>{" "}
          {/* This will help trigger the scroll */}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default SearchDropdown;
