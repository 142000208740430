import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";
import styles from "./DietPlanTable.module.css";
import { useTranslation } from "react-i18next";

const DietPlanTable = ({
  dietItems,
  displayTimes,
  editingItemId,
  formData,
  handleChange,
  handleChangeTime,
  handleSave,
  handleEdit,
  handleDelete,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`table-responsive ${styles["tbl-container"]}`}>
      <table className={`table table-bordered ${styles["diet-plan-table"]}`}>
        <thead>
          <tr>
            <th scope="col">{t("diet.columns.time")}</th>
            <th scope="col">{t("diet.columns.product")}</th>
            <th scope="col">{t("diet.columns.grams")}</th>
            <th scope="col">{t("diet.columns.calories")}</th>
            <th scope="col">{t("diet.columns.protein")}</th>
            <th scope="col">{t("diet.columns.fat")}</th>
            <th scope="col">{t("diet.columns.carbs")}</th>
            <th scope="col" className={`${styles["th-buttons"]}`}>
              {t("")}
            </th>
          </tr>
        </thead>
        <tbody>
          {dietItems.map((item, index) => (
            <tr key={index}>
              {editingItemId === index ? (
                <>
                  <td className={`${styles["active-input"]}`}>
                    <input
                      type="time"
                      name="time"
                      value={formData.time}
                      onChange={handleChangeTime}
                      className={`form-control ${styles["form-control"]}`}
                      step="60"
                      pattern="[0-9]{2}:[0-9]{2}"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="name"
                      disabled={true}
                      value={formData.name}
                      onChange={handleChange}
                      className={`form-control ${styles["form-control"]}`}
                    />
                  </td>
                  <td className={`${styles["active-input"]}`}>
                    <input
                      type="number"
                      name="grams"
                      min="0"
                      value={formData.grams}
                      onChange={handleChange}
                      className={`form-control ${styles["form-control"]}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="calories"
                      disabled={true}
                      min="0"
                      value={formData.calories}
                      onChange={handleChange}
                      className={`form-control ${styles["form-control"]}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="protein"
                      disabled={true}
                      min="0"
                      value={formData.protein}
                      onChange={handleChange}
                      className={`form-control ${styles["form-control"]}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="fat"
                      disabled={true}
                      min="0"
                      value={formData.fat}
                      onChange={handleChange}
                      className={`form-control ${styles["form-control"]}`}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="carbs"
                      disabled={true}
                      min="0"
                      value={formData.carbs}
                      onChange={handleChange}
                      className={`form-control ${styles["form-control"]}`}
                    />
                  </td>
                  <td className={`${styles["td-buttons"]} `}>
                    <button
                      className={`${styles["save-button"]}`}
                      onClick={() => handleSave(index)}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button
                      className={`${styles["delete-button"]}`}
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td>
                    {displayTimes[index] && item.time instanceof Date
                      ? `${String(item.time.getHours()).padStart(
                          2,
                          "0"
                        )}:${String(item.time.getMinutes()).padStart(2, "0")}`
                      : ""}
                  </td>
                  <td className={`${styles["ellipsis-cell"]}`}>
                    {item.product.name}
                  </td>
                  <td className={`${styles["ellipsis-cell"]}`}>
                    {item.grams || 100}
                  </td>
                  <td className={`${styles["ellipsis-cell"]}`}>
                    {item.product.calories}
                  </td>
                  <td className={`${styles["ellipsis-cell"]}`}>
                    {item.product.protein}
                  </td>
                  <td className={`${styles["ellipsis-cell"]}`}>
                    {item.product.fat}
                  </td>
                  <td className={`${styles["ellipsis-cell"]}`}>
                    {item.product.carbs}
                  </td>
                  <td className={`${styles["td-buttons"]}`}>
                    <button
                      className={`${styles["edit-button"]}`}
                      onClick={() => handleEdit(index)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </button>
                    <button
                      className={`${styles["delete-button"]}`}
                      onClick={() => handleDelete(item.id)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DietPlanTable;
