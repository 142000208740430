import { createSlice } from "@reduxjs/toolkit";
// Remove the import for Activity if not used in this slice directly

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    user: {},
    activities: []
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setActivities: (state, action) => {
      // Ensure activities are serialized as plain objects
      state.activities = action.payload.map((activity) => ({
        id: activity.id,
        description: activity.description, // Ensure this is a simple string or serializable structure
        multiplier: activity.multiplier,
      }));
    },
    setSelectedActivity: (state, action) => {
      state.user.activity = {
        id: action.payload.value,
        description: action.payload.label,
        multiplier: action.payload.multiplier,
      };
    },
    resetProfileState: (state) => {
      return {
        user: {},
        activities: [],
      };
    },
  },
});

export const {
  setUser,
  setActivities,
  setSelectedActivity,
  resetProfileState,
} = profileSlice.actions;
export default profileSlice.reducer;
