import React, { useState, useEffect } from "react";
import axios from "axios";
import { useProfile } from "../../../ProfileContext";
import styles from "./Profile.module.css";
import editIcon from "../../../assets/icons/edit-icon.svg";
import cancelIcon from "../../../assets/icons/cancel-icon.svg";
import DietProfile from "../../../models/DietProfile";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfiles } from "../../../redux/slices/profilesDataSlice";
import {
  setUser,
  setActivities,
  setSelectedActivity,
} from "../../../redux/slices/profileSlice";
import useFetchUser from "../../../hooks/useFetchUser";
import { useTranslation } from "react-i18next"; // Import useTranslation from react-i18next
import {
  calculateDailyCalorieNorm,
  calculateDailyProteinLimit,
  calculateDailyFatLimit,
  calculateDailyCarbsLimit,
  calculateTotalCalories,
} from "../../../utils/healthCalculations";
import { validateDigitsInput } from "../../../utils/inputUtils";

const Profile = () => {
  const { t } = useTranslation(); // Use the translation hook
  const { selectedProfile } = useProfile();
  const dispatch = useDispatch();
  const { user, activities } = useSelector((state) => state.profile);
  const [userState, setUserState] = useState(new DietProfile());
  const [initialUser, setInitialUser] = useState(new DietProfile());
  const [isEditing, setIsEditing] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      padding: "0 4px",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
      borderColor: isEditing
        ? "#ff9e9e" // Use the color directly for editing state
        : state.isFocused
        ? "#a0d0a2" // Color when focused
        : "rgba(205, 236, 198, 1)", // Default border color
      borderRadius: "12px",
      boxShadow: isEditing
        ? "0 0 0 0.5px #ff9e9e inset" // Shadow when editing
        : state.isFocused
        ? "0 0 0 2px rgba(160, 208, 162, 0.5)" // Shadow when focused
        : "none",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      transition: "border-color 0.2s, box-shadow 0.2s",
      "&:hover": {
        borderColor: isEditing ? "#ff9e9e" : "rgba(205, 236, 198, 1)",
        boxShadow: state.isFocused
          ? isEditing
            ? "0 0 0 0.5px #ff9e9e inset"
            : "0 0 0 2px rgba(160, 208, 162, 0.5)"
          : "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#e0f7e0"
        : state.isFocused
        ? "#f1f1f1"
        : "#ffffff",
      color: state.isSelected ? "#495057" : "#495057",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      "&:active": {
        backgroundColor: "#c8e6c9",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    input: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const genderSelectStyles = {
    container: (provided) => ({
      ...provided,
      padding: "0 0 0 2px ",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#ffffff",
      border: "none",
      borderRadius: "12px",
      boxShadow: "none",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      transition: "border-color 0.2s, box-shadow 0.2s",
      padding: "0", // Ensure padding is removed
      "&:hover": {
        borderColor: "none",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#e0f7e0"
        : state.isFocused
        ? "#f1f1f1"
        : "#ffffff",
      color: state.isSelected ? "#495057" : "#495057",
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      "&:active": {
        backgroundColor: "#c8e6c9",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    input: (provided) => ({
      ...provided,
      color: "#495057",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const onUserStateSet = (profileData) => {
    setUserState(profileData);
    setInitialized(true);
  };
  const handleEditToggle = () => {
    if (isEditing) {
      setUserState({ ...initialUser }); // Ensure initial state is set when canceling
    }
    toggleEditing(!isEditing);
  };
  const toggleEditing = (state) => {
    setIsEditing(state);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedValue =
      name === "name" || name === "gender" ? value : validateDigitsInput(value);

    // Update the user state, while preserving the selected activity
    const updatedUserState = {
      ...user, // Keep all existing fields
      [name]: updatedValue, // Only change the field being updated
    };

    dispatch(setUser(updatedUserState));
    setUserState(updatedUserState);
  };

  const handleSave = async () => {
    toggleEditing(false);

    if (!user.activity) {
      console.error("Activity not selected");
      return;
    }
    const updatedProfile = {
      name: user.name,
      age: user.age,
      gender: user.gender,
      height: user.height,
      weight: user.weight,
      waist: user.waist,
      hips: user.hips,
      goalWeight: user.goalWeight,
      activity: user.activity,
      bodyMassIndex: user.bodyMassIndex,
      dailyCalorieNorm: user.dailyCalorieNorm,
      selectedCalorieLimit: user.selectedCalorieLimit,
      dailyProteinLimit: user.dailyProteinLimit,
      dailyFatLimit: user.dailyFatLimit,
      dailyCarbsLimit: user.dailyCarbsLimit,
    };

    // Check if any relevant fields have changed
    const generalInfoHasChanges =
      updatedProfile.age !== initialUser.age ||
      updatedProfile.gender !== initialUser.gender ||
      updatedProfile.weight !== initialUser.weight ||
      updatedProfile.height !== initialUser.height ||
      updatedProfile.activity.id !== initialUser.activity.id;
    const calorieInfoHasChanges =
      updatedProfile.dailyProteinLimit !== initialUser.dailyProteinLimit ||
      updatedProfile.dailyCarbsLimit !== initialUser.dailyCarbsLimit ||
      updatedProfile.dailyFatLimit !== initialUser.dailyFatLimit;

    const selectedCalorieLimitHasChanges =
      updatedProfile.selectedCalorieLimit !== initialUser.selectedCalorieLimit;

    const weight = updatedProfile.weight || 0;
    const height = updatedProfile.height || 0;
    const age = updatedProfile.age || 0;
    const activityCoef = updatedProfile.activity.multiplier || 1;
    const gender = updatedProfile.gender || "unknown";

    if (generalInfoHasChanges) {
      const dailyCalorieNorm =
        weight && height && age
          ? calculateDailyCalorieNorm({
              weight,
              height,
              age,
              gender,
              activityCoef,
            })
          : updatedProfile.dailyCalorieNorm || "";

      updatedProfile.dailyCalorieNorm = dailyCalorieNorm;
      updatedProfile.bodyMassIndex =
        weight && height
          ? (weight / (height / 100) ** 2).toFixed(2)
          : updatedProfile.bodyMassIndex;
    }

    if (calorieInfoHasChanges) {
      updatedProfile.selectedCalorieLimit = calculateTotalCalories(
        updatedProfile.dailyProteinLimit,
        updatedProfile.dailyCarbsLimit,
        updatedProfile.dailyFatLimit
      );
    }

    if (selectedCalorieLimitHasChanges) {
      const dailyCalorieNorm =
        weight && height && age
          ? calculateDailyCalorieNorm({
              weight,
              height,
              age,
              gender,
              activityCoef,
            })
          : updatedProfile.dailyCalorieNorm || "";
      const selectedCalorieLimit =
        updatedProfile.selectedCalorieLimit <= 0
          ? dailyCalorieNorm
          : updatedProfile.selectedCalorieLimit;

      const dailyProteinLimit =
        calculateDailyProteinLimit(selectedCalorieLimit);
      const dailyFatLimit = calculateDailyFatLimit(selectedCalorieLimit);
      const dailyCarbsLimit = calculateDailyCarbsLimit(selectedCalorieLimit);
      updatedProfile.dailyProteinLimit = dailyProteinLimit;
      updatedProfile.dailyFatLimit = dailyFatLimit;
      updatedProfile.dailyCarbsLimit = dailyCarbsLimit;
    }

    const activityId = updatedProfile.activity.id;
    updatedProfile.activity = activityId;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile/${selectedProfile.id}`,
        updatedProfile,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            "ngrok-skip-browser-warning": "true",
          },
        }
      );

      if (response.status === 200) {
        console.log("Profile updated successfully");
        dispatch(fetchProfiles());
      } else {
        console.error("Failed to update profile");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleActivityChange = (selectedOption) => {
    dispatch(setSelectedActivity(selectedOption));
  };

  useFetchUser(setInitialUser, onUserStateSet);

  useEffect(() => {
    setIsEditing(false);
  }, [selectedProfile]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/profile/activities`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
              "ngrok-skip-browser-warning": "true",
            },
          }
        );

        const serializableActivities = response.data.map((activity) => ({
          id: activity.id,
          description: activity.description,
          multiplier: activity.multiplier,
        }));

        dispatch(setActivities(serializableActivities));
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    fetchActivities();
  }, [dispatch]);

  useEffect(() => {
    if (user.activity) {
      const selectedOption = activities.find(
        (activity) => activity.id === user.activity.id
      );
      if (!selectedOption) {
        return;
      }
      dispatch(
        setSelectedActivity({
          value: selectedOption.id,
          label: selectedOption.description,
          multiplier: selectedOption.multiplier,
        })
      );
    }
  }, [dispatch]);

  const renderInputField = (field, labelKey, unitKey) => (
    <div
      className={`${styles["info-field"]} align-items-center ${
        isEditing ? styles.editable : ""
      }`}
      key={field}
    >
      <div className="">
        <label htmlFor={field} className={`${styles["info-field-label"]}`}>
          {t(labelKey)} {unitKey ? `(${t(unitKey)})` : ""}
        </label>
        <input
          className={`${styles["info-field-input"]}`}
          id={field}
          name={field}
          value={userState[field] || ""}
          onChange={handleChange}
          readOnly={!isEditing}
        />
      </div>
    </div>
  );

  const renderGenderDropdown = (field) => {
    const genderOptions = [
      { value: "Male", label: t("profile.male") },

      { value: "Female", label: t("profile.female") },
      { value: "Other", label: t("profile.other") },
    ];

    return (
      <div
        className={`${styles["gender-select-warpper"]} ${
          isEditing ? styles.editable : ""
        }`}
        key={field}
      >
        <div>
          <label htmlFor={field} className={`${styles["gender-select-label"]}`}>
            {t("profile.gender")}
          </label>
          <Select
            inputId={field}
            options={genderOptions}
            value={
              genderOptions.find(
                (option) => option.value === userState.gender
              ) || null
            }
            onChange={(option) =>
              handleChange({ target: { name: field, value: option.value } })
            }
            styles={genderSelectStyles}
            isDisabled={!isEditing}
            placeholder={t("profile.selectGender")}
          />
        </div>
      </div>
    );
  };

  const renderDropdown = () => (
    <Select
      options={activities.map((activity) => ({
        value: activity.id,
        label: activity.description,
        multiplier: activity.multiplier,
      }))}
      value={
        user.activity
          ? {
              value: user.activity.id,
              label: user.activity.description,
              multiplier: user.activity.multiplier,
            }
          : null
      }
      onChange={handleActivityChange}
      styles={customStyles}
      placeholder={t("profile.selectActivity")} // Use translation key for placeholder
      classNamePrefix="select" // Ensure CSS module styles are applied
      isDisabled={!isEditing} // Disable the dropdown when not editing
    />
  );

  const renderSelectedField = (field, labelKey, unitKey, editable) => {
    return (
      <div className={`${styles["card-col"]} col`} key={field}>
        <div
          className={`${styles.card} card h-100 radius-16 shadow-none mb-0 ${
            isEditing && editable ? styles.editable : ""
          }`}
        >
          <div className={`${styles["card-body"]} card-body text-black`}>
            <div className={`${styles["card-text"]}`}>
              <label className={styles["card-label"]} htmlFor={field}>
                {t(`profile.${labelKey}`)} {/* Translate label */}
              </label>
              <input
                id={field}
                name={field}
                value={
                  userState[field] === null
                    ? ""
                    : userState[field] +
                      (isEditing === false && userState[field] === ""
                        ? "0"
                        : "") +
                      (isEditing === true && editable === true
                        ? ""
                        : userState[field] === ""
                        ? ""
                        : " " + t(`profile.${unitKey}`))
                }
                readOnly={!(editable === true && isEditing === true)}
                className={`${
                  isEditing === true && editable === true
                    ? styles["active-input"]
                    : ""
                } ${styles["card-input"]}`}
                onChange={handleChange}
              />
            </div>
            <div className="ms-auto fs-2"></div>
          </div>
        </div>
      </div>
    );
  };

  return (
    initialized && (
      <div className={`${styles.container} container`}>
        <div
          className={`row row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-xl-2 row-cols-xxl-2 w-100 gx-0`}
        >
          <div
            className={`${styles["left-side"]} col-5 col-sm-5 col-md-5 col-lg-5 col-xl-4 col-xxl-4 offset-xl-1 offset-xxl-1`}
          >
            {/* Client info table */}
            <div className={`${styles["client-info"]}`}>
              <div className={`${styles["header-1"]} ${styles["header-text"]}`}>
                {t(`profile.clientInfo`)}
              </div>
              <div className={`${styles["info-box"]}`}>
                <div
                  className={`${styles["info-top-box"]} ${
                    isEditing ? styles.editable : ""
                  }`}
                >
                  <div className="">
                    <img
                      src="https://i.imgur.com/OMcdAnC.png"
                      className={`${styles["rounded-circle"]}`}
                      alt="Profile Avatar"
                    />
                  </div>
                  <div className={styles["info-field-container"]}>
                    <div className="">
                      <label
                        htmlFor="name"
                        className={`${styles["info-field-label"]}`}
                      >
                        {t("profile.fullName")}
                      </label>
                      <input
                        className={`${styles["info-field-input"]}`}
                        id="name"
                        name="name"
                        value={userState.name || ""}
                        onChange={handleChange}
                        readOnly={!isEditing}
                      />
                    </div>
                    <div className="">
                      <label
                        htmlFor="age"
                        className={`${styles["info-field-label"]}`}
                      >
                        {t("profile.age")}
                      </label>
                      <input
                        className={`${styles["info-field-input"]}`}
                        id="age"
                        name="age"
                        value={userState.age || ""}
                        onChange={handleChange}
                        readOnly={!isEditing}
                      />
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className={`${styles["edit-button"]}`}
                      onClick={handleEditToggle}
                    >
                      {isEditing ? (
                        <img src={cancelIcon} alt="Cancel" />
                      ) : (
                        <img src={editIcon} alt="Edit" />
                      )}
                    </button>
                  </div>
                </div>
                {renderGenderDropdown("gender")}
                {renderInputField("weight", "profile.weight", "profile.kg")}
                {renderInputField("height", "profile.height", "profile.cm")}
                {renderInputField("waist", "profile.waist", "profile.cm")}
                {renderInputField("hips", "profile.hips", "profile.cm")}
                {renderInputField(
                  "goalWeight",
                  "profile.aimWeight",
                  "profile.kg"
                )}
              </div>
              <div className="">
                <button
                  type="button"
                  className={`${styles["save-button"]}`}
                  onClick={handleSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <div
            className={`${styles["right-side"]} col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7`}
          >
            {/* Selected limit table */}
            <div className={styles["dietary-info"]}>
              <div className={`${styles["dietary-data"]} row`}>
                <div
                  className={`${styles["header-1"]} ${styles["header-text"]}`}
                >
                  {t(`profile.dietaryData`)}
                </div>
                <div
                  className={`${styles["card-container"]} row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-xl-1 row-cols-xxl-1`}
                >
                  {renderDropdown()} {/* Render the dropdown */}
                  {renderSelectedField(
                    "bodyMassIndex",
                    "bodyMassIndex",
                    "",
                    false
                  )}
                  {renderSelectedField(
                    "dailyCalorieNorm",
                    "dailyCalorieNorm",
                    "kcal",
                    false
                  )}
                  {renderSelectedField(
                    "selectedCalorieLimit",
                    "selectedCalorieLimit",
                    "kcal",
                    true
                  )}
                </div>
                <div>
                  <div
                    className={`${styles["card-narrow-container"]} row row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-xl-3 row-cols-xxl-3`}
                  >
                    {renderSelectedField(
                      "dailyProteinLimit",
                      "protein",
                      "g",
                      true
                    )}
                    {renderSelectedField("dailyCarbsLimit", "carbs", "g", true)}
                    {renderSelectedField("dailyFatLimit", "fat", "g", true)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Profile;
