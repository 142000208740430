import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en.json';
import uk from '../locales/uk.json';
import Backend from 'i18next-http-backend'; // If using the backend plugin
import axios from 'axios';

i18n
    .use(Backend) // Use the backend plugin if loading from the server
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            uk: {
                translation: uk,
            }
        },
        lng: 'uk', // Default language
        fallbackLng: 'uk',
        interpolation: {
            escapeValue: false, // React already safes from xss
        },
        react: {
            useSuspense: false, // Disable suspense to prevent flickering
        },
        preload: ['en', 'uk'], // Preload both languages
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
            // If you need a custom request to cache responses
            request: (options) => {
                return axios.get(options.loadPath)
                    .then(response => {
                        return response.data; // Cache the response
                    });
            },
        },
    });

export default i18n;
