export function calculateDailyCalorieNorm({
  weight,
  height,
  age,
  gender,
  activityCoef,
}) {
  let calorieNorm;
  if (gender === "Female") {
    calorieNorm = (10 * weight + 6.25 * height - 5 * age - 161) * activityCoef;
  } else {
    calorieNorm = (10 * weight + 6.25 * height - 5 * age + 5) * activityCoef;
  }
  return Math.round(calorieNorm);
}

export function calculateDailyProteinLimit(calorieLimit) {
  return Math.round((0.3 * calorieLimit) / 4);
}

export function calculateDailyFatLimit(calorieLimit) {
  return Math.round((0.3 * calorieLimit) / 9);
}

export function calculateDailyCarbsLimit(calorieLimit) {
  return Math.round((0.4 * calorieLimit) / 4);
}

export function calculateTotalCalories(protein, carbs, fat) {
  return Math.round(protein * 4 + carbs * 4 + fat * 9);
}
